import './project.scss'

function Project(props) {
  const handleClick = () => {
    props.openModal(props.id)
  }

  return (
    <div className="project-item">
      <h2 className="project-item-title">{props.name}</h2>
      <div className="project-item-contain">
        <a href={props.url} target="blank">
          <img src={props.image} alt="Page d'accueil du site web" />
        </a>
        <button className="project-btn" onClick={handleClick}>
          En savoir plus
        </button>
      </div>
    </div>
  )
}

export default Project
