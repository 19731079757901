import './school.scss'
import { useEffect, useRef } from 'react'

function School(props) {
    const schoolRef = useRef()
    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    }
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                schoolRef.current.classList.add('visible')
                observer.unobserve(schoolRef.current)
            }
        }, options)
        observer.observe(schoolRef.current)
    }, [])

    return (
        <li className="school-elmt-contain" ref={schoolRef}>
            <img src={props.image} alt="school logo" className="school-img" />
            <div className="school-elmt">
                <span>{props.date}</span>
                <p className="school-name">{props.name}</p>
                <p className="school-location">{props.location}</p>
            </div>
        </li>
    )
}

export default School
