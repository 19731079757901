import './skill.scss'
//import { useRef, useEffect } from 'react'

function Skill(props) {
    /*
    const skillRef = useRef()

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 1,
    }
    useEffect(() => {
        const circle = document.querySelectorAll('.circle-solid')
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                circle.forEach((e) => e.classList.add('circle-animation'))
                observer.unobserve(skillRef.current)
            }
        }, options)
        observer.observe(skillRef.current)
    }, [])*/

    return (
        <div className="skill-container">
            <img src={props.src} alt={props.alt} className='skill-img'/>
        </div>
    )
}

export default Skill

/*<div className="skill-container" ref={skillRef}>
            <h3>{props.name}</h3>
            <svg>
                <circle
                    className="circle-regular"
                    cx="70px"
                    cy="70px"
                    r="70px"
                ></circle>
                <circle
                    className={`circle-solid ${props.language}`}
                    cx="70px"
                    cy="70px"
                    r="70px"
                ></circle>
            </svg>
            <span className="skill-percentage">{props.percentage}</span>
        </div> */