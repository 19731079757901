import Banner from '../../components/banner/banner'
import Presentation from '../../components/presentation/presentation'
import schoolList from '../../data/school.json'
//import experienceList from '../../data/experience.json'
import skillList from '../../data/skill.json'
import './home.scss'
import School from '../../components/school/school'
//import Experience from '../../components/experience/experience'
import Skill from '../../components/skill/skill'
import Contact from '../../components/contact/contact'
import FilterProject from '../../components/filterProject/filterProject'

function Home() {
  return (
    <main>
      <Banner />
      <Presentation />
      <div className="formation" id="parcours">
        <h2 className="formation-title">Mon parcours :</h2>
        <section className="school">
          <h3 className="school-title">Mon parcours scolaire : </h3>
          <ul className="school-list">
            {schoolList.map((list, index) => (
              <School
                key={index}
                date={list.date}
                name={list.name}
                location={list.location}
                image={list.image}
              />
            ))}
          </ul>
        </section>
        {/* <section className="experience">
                    <h3 className="experience-title">
                        Mon parcours professionnel :
                    </h3>
                    <ul className="experience-list">
                        {experienceList.map((list, index) => (
                            <Experience
                                key={index}
                                date={list.date}
                                name={list.name}
                                location={list.location}
                                task={list.task}
                                image={list.image}
                            />
                        ))}
                    </ul>
                </section> */}
      </div>
      <div className="skills" id="competences">
        <h3 className="skill-title">Mes compétences : </h3>
        <div className="skill-bloc">
          {skillList.map((list, index) => (
            <Skill src={list.src} alt={list.alt} key={index} />
          ))}
        </div>
      </div>
      <div id="projets">
        <h2 className="project-title">Mes projets :</h2>
        <FilterProject />
      </div>
      <Contact />
    </main>
  )
}

export default Home
