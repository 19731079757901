import { useState } from 'react'
import projectList from '../../data/project.json'
import Project from '../../components/project/project'
import Modal from '../../components/modal/modal'
import './filterProject.scss'

function FilterProject() {
  const [allProject, setAllProject] = useState(true)
  const [category, setCategory] = useState()
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [classname, setClassname] = useState('')
  let filteredProject = []

  const changeCategory = (evtType) => {
    if (evtType.target.innerText !== 'Tous') {
      setCategory(evtType.target.innerText.toLowerCase())
      setAllProject(false)
    } else {
      setAllProject(true)
    }
  }

  const handleClick = (evt) => {
    setClassname(evt.target.innerText)
    changeCategory(evt)
  }

  if (allProject === true) {
    filteredProject = projectList
  } else {
    filteredProject = projectList.filter((event) => event.category === category)
  }

  const openModal = (projectId) => {
    setSelectedProjectId(projectId)
  }

  const closeModal = () => {
    setSelectedProjectId(null)
  }


  return (
    <div>
      {
      <div className="filter">
        
        <button
          className={classname === 'Tous' ? 'btn-filter active' : 'btn-filter'}
          onClick={handleClick}
        >
          Tous
        </button>
        <button
          className={
            classname === 'Formation' ? 'btn-filter active' : 'btn-filter'
          }
          onClick={handleClick}
        >
          Formation
        </button>
        <button
          className={
            classname === 'Personnel' ? 'btn-filter active' : 'btn-filter'
          }
          onClick={handleClick}
        >
          Personnel
        </button>
        <button
          className={
            classname === 'Professionnel' ? 'btn-filter active' : 'btn-filter'
          }
          onClick={handleClick}
        >
          Professionnel
        </button>
        </div> }
      <div className="project">
        {filteredProject.map((list) => (
          <Project
            key={list.id}
            name={list.name}
            url={list.url}
            image={list.image}
            language={list.language}
            description={list.description}
            github={list.github}
            id={list.id}
            openModal={openModal}
          />
        ))}
      </div>
      {selectedProjectId && (
        <Modal
          project={projectList.find((p) => p.id === selectedProjectId)}
          closeModal={closeModal}
        />
      )}
    </div>
  )
}

export default FilterProject
