import './contact.scss'
import { useForm, ValidationError } from '@formspree/react'

function Contact() {
    const [state, handleSubmit] = useForm('xvojvbpz')
    let formMessage = null
    const contactForm = document.querySelector('.contact-form')

    if (state.succeeded) {
        formMessage = (
            <p className="validation-message">
                Votre message à bien été envoyé !{' '}
            </p>
        )
        contactForm.reset()
    }

    return (
        <div id="contact">
            <h2 className="contact-title">Me contacter :</h2>
            <form className="contact-form" onSubmit={handleSubmit}>
                <div className="input-container">
                    <label htmlFor="nom">Nom : </label>
                    <input type="text" id="nom" name="name" required></input>
                    <ValidationError
                        prefix="Nom"
                        field="nom"
                        errors={state.errors}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="prenom">Prenom :</label>
                    <input
                        type="text"
                        id="prenom"
                        name="prenom"
                        required
                    ></input>
                    <ValidationError
                        prefix="Prenom"
                        field="prenom"
                        errors={state.errors}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="email">Adresse e-mail :</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        required
                    ></input>
                    <ValidationError
                        prefix="Email"
                        field="email"
                        errors={state.errors}
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="message">Message :</label>
                    <textarea
                        type="text"
                        id="message"
                        name="message"
                        rows="6"
                    ></textarea>
                    <ValidationError
                        prefix="Message"
                        field="message"
                        errors={state.errors}
                    />
                </div>
                <button
                    className="btn-submit"
                    type="submit"
                    disabled={state.submitting}
                >
                    Envoyer
                </button>
                {formMessage}
            </form>
        </div>
    )
}

export default Contact
