import './presentation.scss'

function Presentation() {
  return (
    <div className="presentation" id="presentation">
      <h2 className="presentation-title">A propos de moi :</h2>
      <div className="presentation-content">
        <div className="presentation-description">
          <h3>Bonjour,</h3>
          <p>
            Je m'appelle Florian Gay et j'ai 24 ans. J'ai terminé une formation
            d'Intégrateur Web chez OpenClassrooms qui m'a permis de découvrir
            l'environnement du front-end, avec plusieurs langages comme l'HTML,
            le CSS, le JavaScript, et React. Ma prochaine étape est d'acquérir
            de nouvelles compétences grâce à une formation de développeur Full
            Stack à la 3W Academy. Cette formation en alternance va me permettre
            de découvrir de nouvelles notions telles que le PHP, Node.JS, ou
            encore SQL, ainsi que le monde professionnel.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Presentation
