import './footer.scss'
import { mailIcon, linkedinIcon, githubIcon } from '../../assets/svg'

function Footer() {
  return (
    <footer>
      <span className="footer-span">
        © 2023 Gay Florian, Tous droits réservés.
      </span>
      <ul>
        <li>
          <a href="mailto:floriangay750@gmail.com" className="footer-link">
            {mailIcon}
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/florian-gay-174a18295/"
            className="footer-link"
            target="blank"
          >
            {linkedinIcon}
          </a>
        </li>
        <li>
          <a
            href="https://github.com/FlorianGay"
            className="footer-link"
            target="blank"
          >
            {githubIcon}
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
