import { crossIcon, githubIcon } from '../../assets/svg'
import './modal.scss'

function Modal(props) {
  const { project, closeModal } = props

  if (!project) {
    return null
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-modal" onClick={closeModal}>
          {crossIcon}
        </button>
        <h3 className="modal-title">{project.name}</h3>
        <a href={project.url} target="blank">
          <img src={project.image} alt={project.name} className="modal-img" />
        </a>
        <p className="modal-description">{project.description}</p>
        <div className="modal-contain">
          <ul className="modal-list">
            {project.language.map((lang, index) => (
              <li key={index} className="modal-list-elmt">
                {lang}
              </li>
            ))}
          </ul>
          <div className="modal-nav">
            <a href={project.github} target="blank" className="modal-link">
              Voir sur GitHub {githubIcon}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
