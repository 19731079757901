import { useState, useEffect } from 'react'
import { barIcon } from '../../assets/svg'
import './header.scss'

function Header() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [onMobile, setOnMobile] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [prevScrollY, setprevScrollY] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener('resize', handleResize)
        if (screenWidth <= 768) {
            setOnMobile(true)
        } else {
            setOnMobile(false)
        }
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [screenWidth])

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY
            if (currentScrollY > prevScrollY && screenWidth > 768) {
                document.querySelector('header').classList.add('header-hidden')
            } else {
                document
                    .querySelector('header')
                    .classList.remove('header-hidden')
            }

            setprevScrollY(currentScrollY)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })

    const open = () => {
        if (isOpen === false) {
            setIsOpen(true)
        } else {
            setIsOpen(false)
        }
    }
    return (
        <header>
            <div className="header-contain">
                <span className="header-name">Florian Gay</span>

                <nav className="header-nav">
                    {onMobile ? (
                        <button
                            className="header-btn"
                            onClick={open}
                            aria-label="afficher menu"
                        >
                            {barIcon}
                        </button>
                    ) : (
                        ''
                    )}
                    <ul
                        className={
                            isOpen && onMobile
                                ? 'header-nav-appear'
                                : onMobile
                                ? 'header-nav-disappear'
                                : ''
                        }
                    >
                        <li>
                            <a href="#presentation" className="header-nav-link">
                                Présentation
                            </a>
                        </li>
                        <li>
                            <a href="#parcours" className="header-nav-link">
                                Mon parcours
                            </a>
                        </li>
                        <li>
                            <a href="#competences" className="header-nav-link">
                                Competences
                            </a>
                        </li>
                        <li>
                            <a href="#projets" className="header-nav-link">
                                Mes projets
                            </a>
                        </li>
                        <li>
                            <a href="#contact" className="header-nav-link">
                                Contact
                            </a>
                        </li>
                        <li>
                            <a href="https://storage.googleapis.com/florian_gay_image/CV%20Florian%20Gay.pdf" className="header-nav-link" target='blank'>
                                CV
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )
}

export default Header
