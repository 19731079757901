import './banner.scss'
import profilePicture from '../../assets/photo-profile.jpg'
import profilePictureMobile from '../../assets/photo-profile-mobile.jpg'
import { useState, useEffect } from 'react'

function Banner() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [onMobile, setOnMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    if (screenWidth <= 768) {
      setOnMobile(true)
    } else {
      setOnMobile(false)
    }
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [screenWidth])

  return (
    <div className="banner">
      <div className="banner-img">
        <img
          src={onMobile ? profilePictureMobile : profilePicture}
          alt="profile representation"
          className="presentation-img"
        />
      </div>
      <div className="banner-content">
        <h1 className="banner-title">Gay Florian</h1>
        <span className="banner-description">
          Développeur Front-End (React/JavaScript)
        </span>
      </div>
    </div>
  )
}

export default Banner
