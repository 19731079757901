import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Home from './pages/home/home';
import './style/global.scss'
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
